import React, { useState, useEffect } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { useFormik } from "formik"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import * as Yup from "yup"
import { setUser, updateUser, getUserList, getUserDetail } from "store/actions"
import AuthUser from "components/AuthUser/AuthUser"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Label,
  Row,
  Form,
  FormFeedback,
  Button,
  Input,
  CardTitle,
} from "reactstrap"

function index(props) {
  const {
    onSetUser,
    userCreated,
    loading,
    onUpdateUser,
    userUpdated,
    onGetUsersList,
    usersList,
    onGetUserDetail,
    userDetail,
  } = props
  const [parseValue, setParseValue] = useState("")
  const [checkJson, setCheckJson] = useState()
  const [usersLists, setUsersLists] = useState([])
  const [userDetails, setUserDetails] = useState({})
  const user = useState(AuthUser())
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
  const initialValues = {
    jsonValue: "",
  }
  const validationSchema = Yup.object({
    jsonValue: Yup.string().required("Please enter a valid JSON fomrat."),
  })
  const onSubmit = () => {
    if (
      parseValue &&
      (JSON.parse(parseValue)._id === "" ||
        JSON.parse(parseValue)._id === null) &&
      checkJson
    ) {
      onSetUser(JSON.parse(parseValue))
    } else if (
      parseValue &&
      (JSON.parse(parseValue)._id !== "" ||
        JSON.parse(parseValue)._id !== null) &&
      checkJson
    ) {
      onUpdateUser(JSON.parse(parseValue))
    }
  }
  const validation = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    onGetUsersList()
  }, [])
  useEffect(() => {
    //console.log(usersList)
    if (Array.isArray(usersList)) {
      setUsersLists(usersList)
    }
  }, [usersList])
  useEffect(() => {
    // console.log(usersLists)
  }, [usersLists])
  useEffect(() => {}, [validation.values])
  useEffect(() => {}, [loading])
  useEffect(() => {}, [userCreated])
  useEffect(() => {}, [userUpdated])
  useEffect(() => {}, [checkJson])
  useEffect(() => {}, [parseValue])
  useEffect(() => {
    if (userDetail) {
      setUserDetails(userDetail)
    }
  }, [userDetail])
  useEffect(() => {
    if (userDetails) {
      validation.setValues({
        jsonValue: JSON.stringify(userDetails),
      })
    }
  }, [userDetails])
  function handleParseString(value) {
    if (value) {
      setParseValue(JSON.parse(value))
    }
  }
  function handleClearInput() {
    validation.setValues({
      jsonValue: "",
    })
    setParseValue("")
    setUserDetails({})
  }
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 10000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: false,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 300,
  }
  function showToastSuccess(message) {
    toastr.success(`Successfuly ${message}`, "")
    sleep(1000)
    validation.resetForm()
    window.location.reload()
  }
  function showToastInfo(message) {
    toastr.info(message)
  }
  function showToastError(message) {
    toastr.error(`${message} , Something went wrong`, "")
    //handleReload()
  }
  function handleCheckJson(value) {
    try {
      let o = JSON.parse(value)
      if (typeof o === "object") {
        setCheckJson(true)
      } else {
        setCheckJson(false)
      }
    } catch {
      setCheckJson(false)
    }
  }
  function handleUserSelect(value) {
    //console.log(value)
    if (value) {
      onGetUserDetail(value)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid>
          {userCreated && showToastSuccess("Created")}
          {userCreated === false && showToastError("Not Created")}
          {userUpdated && showToastSuccess("Updated")}
          {userUpdated === false && showToastError("Not Updated")}
          <Breadcrumbs title="User" breadcrumbItem="User Json" />
          <Row>
            <Col lg="12">
              {loading ? (
                <Row className="spinner-custom mx-auto my-auto pb-3">
                  <LoadingSpinnerThreeDots />
                </Row>
              ) : (
                <Form onSubmit={validation.handleSubmit}>
                  <Row>
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <Label>
                            User: <b>{userDetails?.userName}</b>
                          </Label>

                          <Input
                            name="userDetails"
                            type="select"
                            onChange={e => {
                              handleUserSelect(e.target.value)
                              setParseValue("")
                            }}
                            value={userDetails?.userName}
                            className="my-3"
                          >
                            <option value="" defaultValue hidden>
                              Select
                            </option>
                            {usersLists &&
                              usersLists?.map((users, index) => {
                                return (
                                  <option value={users.userId} key={index}>
                                    {users.userName}
                                  </option>
                                )
                              })}
                          </Input>
                          <Input
                            className="text-size-json"
                            type="textarea"
                            rows="15"
                            name="jsonValue"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.jsonValue}
                            invalid={
                              validation.touched.jsonValue &&
                              validation.errors.jsonValue
                                ? true
                                : false
                            }
                          />
                          {validation.touched.jsonValue &&
                          validation.errors.jsonValue ? (
                            <FormFeedback status="invalid">
                              {validation.errors.jsonValue}
                            </FormFeedback>
                          ) : null}
                          {checkJson === false &&
                          !validation.errors.jsonValue ? (
                            <>
                              <small className="color-danger">
                                Please check your JSON string.
                              </small>
                              <br />
                            </>
                          ) : null}
                          {userUpdated === false && userCreated === false ? (
                            <>
                              <small className="color-danger">
                                Please check your JSON string.
                              </small>
                              <br />
                            </>
                          ) : null}
                          <Row className="mx-auto my-1">
                            <Col>
                              {" "}
                              <small>
                                Please <b>Parse</b> your JSON format before{" "}
                                <b>Saving</b>.
                              </small>
                            </Col>
                          </Row>
                          <Button
                            color="warning"
                            className="my-2 mx-auto"
                            onClick={() => {
                              if (
                                typeof validation.values.jsonValue !== "object"
                              ) {
                                handleParseString(
                                  JSON.stringify(validation.values.jsonValue)
                                )
                              } else {
                                handleParseString(validation.values.jsonValue)
                              }
                              showToastInfo("Parsed JSON Format")
                              setCheckJson()
                              handleCheckJson(validation.values.jsonValue)
                            }}
                          >
                            <i className="mdi mdi-code-json mx-1" />
                            Parse
                          </Button>
                          {parseValue ? (
                            <Button
                              color="info"
                              className=" ml-2 "
                              onClick={() => {
                                showToastInfo("JSON copied.")
                                navigator.clipboard.writeText(parseValue)
                              }}
                            >
                              <i className="mdi mdi-content-copy mx-1" />
                              Copy Value
                            </Button>
                          ) : null}
                          {parseValue ? (
                            <Button
                              color="danger"
                              className=" ml-2 "
                              onClick={() => {
                                handleClearInput()
                              }}
                            >
                              <i className="mdi mdi-content-copy mx-1" />
                              Clear Value
                            </Button>
                          ) : null}
                          {parseValue && checkJson === true ? (
                            <Button
                              color="success"
                              className=" ml-2 "
                              type="submit"
                            >
                              <i className="mdi mdi-plus mx-1" />
                              Save User
                            </Button>
                          ) : null}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card>
                        <CardTitle className="mx-auto my-2">
                          Parsed Json
                        </CardTitle>
                        <CardBody className="text-size-json">
                          {parseValue
                            .split(/(?:^|,)((?:[^\",]|\"[^\"]*\")*)/)
                            .map((value, index) => {
                              return (
                                <Row>
                                  <Col>
                                    {value}
                                    {index !==
                                      parseValue.split(
                                        /(?:^|,)((?:[^\",]|\"[^\"]*\")*)/
                                      ).length -
                                        2 && value !== ""
                                      ? ","
                                      : ""}
                                  </Col>
                                </Row>
                              )
                            })}
                          <br />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {
    loading: state.Users.loading,
    userCreated: state.Users.userCreated,
    userUpdated: state.Users.update,
    usersList: state.Users.usersList,
    userDetail: state.Users.user,
  }
}

const mapDispatchToProps = dispatch => ({
  onSetUser: data => dispatch(setUser(data)),
  onUpdateUser: data => dispatch(updateUser(data)),
  onGetUsersList: () => dispatch(getUserList()),
  onGetUserDetail: data => dispatch(getUserDetail(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
