import React, { useContext, useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { PaymentMethodContext } from "../../index"
import classnames from "classnames"
var image = "/cards/naps-maroon.png"

const DebitRedirect = props => {
  const { dbsyDebitRedirect } = props

  const { onSetDibsyInitiatePayment } = useContext(PaymentMethodContext)

  const [clicked, setClicked] = useState(false)

  const pay = () => {
    setClicked(true)
    var model = {
      SessionId: null,
      TrackId: dbsyDebitRedirect.trackId,
      PaymentMethod: "debit",
    }
    onSetDibsyInitiatePayment(model)
  }

  return (
    <>
      <Button
        width="100%"
        className={classnames(
          "btn-outline-naps btn-block inner mb-2",
          "fw-medium"
        )}
        onClick={() => pay()}
        disabled={clicked}
      >
        <span
          style={{
            border: "1px solid",
            borderRadius: "0.05rem",
            padding: "2px",
            fontSize: "7px",
            fontStyle: "italic",
            verticalAlign: "middle",
          }}
        >
          NAPS
        </span>
        <span style={{ fontSize: "1rem" }}>{props.t(" DEBIT CARD")}</span>
      </Button>
    </>
  )
}

DebitRedirect.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(DebitRedirect))
